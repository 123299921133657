/* eslint-disable import/no-anonymous-default-export */

// export const API_BASE_URL = "https://uat.oneflo.in:"; // BU7132242844 //For Dev/UAT
// export const API_BASE_URL = "https://preprod.oneflo.in:"; // BU6098926543 //For PreProd
// export const API_BASE_URL = "https://oneflo.in:"; // BU6098926543 // For Prod

// export const BUSINESS_ID = 'BU6098926543' // Dev/UAT server
export const BUSINESS_ID = '' // Dev/UAT server

const APP_URL = process.env.REACT_APP_URL;
const DSA_API_URL = process.env.REACT_APP_DSA_API_URL;
const COMMON_API_URL = process.env.REACT_APP_COMMON_API_URL;
const LENDER_ECO_SYSTEM_API_URL = process.env.REACT_APP_LENDER_ECO_SYSTEM_API_URL;
const BATCH_URL = process.env.REACT_APP_BATCH_URL

const CLIENT_NAME = "Client Name";
const IMAGE_BASE_URL = "";
export default {
    AppUrl: APP_URL,
    ApiUrlOne: DSA_API_URL,
    ApiUrlTwo: COMMON_API_URL,
    ApiUrlThree: LENDER_ECO_SYSTEM_API_URL,
    clientName: CLIENT_NAME,
    ImageBaseUrl: IMAGE_BASE_URL,
    ApiUrlBatch: BATCH_URL
};
